.sui-layout-header {
  padding: 0;
  margin-bottom: 20px;
}

.sui-search-box__submit {
  background: #096b7f;
}

.sui-search-box__submit:hover {
  background: #096b7f;
}

span em {
  font-style: normal;
  font-weight: 500;
  color: #ffd563;
}
